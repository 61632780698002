import MobileSidebar from './MobileSidebar';
import MobileSidebarButton from './MobileSidebarButton';
import Sidebar from './Sidebar';

export default function SiteWrapper({ children }: { children: React.ReactNode }) {
  return (
    <>
      <div className="pb-20">
        <MobileSidebar />

        <div className="hidden p-2 sm:fixed sm:inset-y-0 sm:z-50 sm:flex md:w-80 sm:w-64 lg:w-96 sm:flex-col ">
          <Sidebar />
        </div>

        <MobileSidebarButton />

        <main className="pb-12 md:pr-4 md:pl-80 sm:pl-64 lg:pl-96">
          <div className="px-2 sm:bg-gray-100 sm:rounded-3xl sm:mt-2 sm:p-6 min-h-[calc(100vh-16px)] overflow-y-scroll ">
            {children}
          </div>
        </main>
      </div>

    </>
  );
}
