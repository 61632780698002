import { useEffect, useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { ArrowUp, Camera, Plus } from 'lucide-react';
import posthog from 'posthog-js';
import { AiOutlineLoading } from 'react-icons/ai';
import { BsCapsulePill } from 'react-icons/bs';
import { GoNote } from 'react-icons/go';
import MedIcon from '@/components/MedIcon';
import DrugSelector from '@/components/Medications/DrugSelector';
import Dosage from '@/components/Medications/Views/Dosage';
import Preference from '@/components/Medications/Views/Preference';
import Variant from '@/components/Medications/Views/Variant';
import Modal from '@/components/Modals/Modal';
import { Button } from '@/components/ui/button.tsx';
import { Input } from '@/components/ui/input.tsx';
import { Label } from '@/components/ui/label.tsx';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Switch } from '@/components/ui/switch';
import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table';
import { Textarea } from '@/components/ui/textarea.tsx';
import useDrugRecords from '@/hooks/useDrugRecords';
import { useToast } from '@/hooks/useToast';
import { IDrugBase, IMedication, IRequest } from '@/types.ts';
import axiosClient from '@/utils/axiosClient.ts';

interface MedicationModalProps {
  request?: IRequest;
  medication?: IMedication;
  medications: IMedication[];
  defaultPath?: string;
  userId: string;
  accountId: string;
}

const MedicationModal = NiceModal.create(
  ({ request, medication, defaultPath, medications, userId, accountId }: MedicationModalProps) => {
    const { toast } = useToast();
    const { data: drugRecords = [] } = useDrugRecords();

    const modal = useModal('medication-modal');
    const photoModal = useModal('medication-photo-modal');

    const [path, setPath] = useState(medication ? 'newMedication' : defaultPath ? defaultPath : 'choosePath');
    const [selectedDrug, setSelectedDrug] = useState<string>(medication?.medication || '');
    const [drug, setDrug] = useState<IDrugBase>(
      drugRecords.find((r) => r.data.name === medication?.medication)?.data || ({} as IDrugBase),
    );
    const [label, setLabel] = useState(medication?.label || '');
    const [quantity, setQuantity] = useState(medication?.quantity || '');
    const [partialStockAllowed, setPartialStockAllowed] = useState(medication?.partial_stock_allowed || false);
    const [note, setNote] = useState(medication?.note || '');
    const [isSaving, setIsSaving] = useState(false);
    const [prescription, setPrescription] = useState({
      category: medication?.category || '',
      medication: medication?.medication || '',
      variant: medication?.variant || '',
      dosage: medication?.dosage || '',
      preference: medication?.preference || '',
    });

    useEffect(() => {
      if (medication && medication.id) {
        setPath('newMedication');
        setSelectedDrug(medication.medication);
        setDrug(drugRecords.find((r) => r.data.name === medication.medication)?.data || ({} as IDrugBase));

        setLabel(medication.label);
        setQuantity(medication.quantity);
        setPartialStockAllowed(medication.partial_stock_allowed);
        setNote(medication.note);
        setPrescription({
          category: medication.category,
          medication: medication.medication,
          variant: medication.variant,
          dosage: medication.dosage,
          preference: medication.preference,
        });
      }
    }, [medication?.id, medications, drugRecords]);

    const handleDrugSelect = (drugName: string) => {
      setSelectedDrug(drugName);
      const foundDrug = drugRecords.find((r) => r.data.name === drugName)?.data;
      setDrug(foundDrug || ({} as IDrugBase));
      setPrescription({
        category: foundDrug?.category || '',
        medication: drugName,
        variant: '',
        dosage: '',
        preference: '',
      });
      updatePrescription('category', foundDrug?.category || '');
      updatePrescription('medication', drugName);
    };

    const saveMedication = async () => {
      if (!quantity || !prescription.dosage || !prescription.variant) {
        alert('Please fill out all required fields.');
        return;
      }

      setIsSaving(true);
      posthog.capture('medication_added');

      const dosageForm = drug?.variants.find((variant) => variant.name === prescription.variant)?.dosageForm;

      const payload = {
        user_id: userId,
        category: prescription.category,
        medication: prescription.medication,
        variant: prescription.variant,
        dosage: prescription.dosage,
        dosage_form: dosageForm,
        preference: prescription.preference,
        quantity,
        partial_stock_allowed: partialStockAllowed,
        note,
        label,
        request_id: request?.id,
        account_id: accountId,
      };

      try {
        if (medication?.id) {
          await axiosClient.put(`/v1/medications/${medication.id}`, payload);
        } else {
          const medication_response = await axiosClient.post(`/v1/medications`, payload);

          if (medication_response.data?.id) {
            toast({
              title: 'Medication Created',
              description: 'Medication created successfully.',
              duration: 5000,
            });
          }
        }
        window.location.reload();
      } catch (error) {
        console.error('Error saving medication:', error);
        alert('An error occurred while saving the medication.');
      } finally {
        setIsSaving(false);
      }
    };

    const updatePrescription = (key: string, value: string) => {
      setPrescription((prev) => ({
        ...prev,
        [key]: value,
      }));
    };

    const canViewVariant = prescription.medication !== '';
    const canViewDosage = canViewVariant && prescription.variant;
    const canViewPreference = canViewDosage && prescription.dosage;
    const canViewTheRest = canViewPreference && prescription.preference;

    const nonselectedMedications = medications?.filter(
      (medication: any) => !request?.medications.find((m: any) => m.id === medication.id),
    ) as IMedication[];

    const overridePath = !medication?.id
      ? nonselectedMedications.length > 0
        ? 'choosePath'
        : 'newMedication'
      : 'newMedication';
    const title = !medication?.id
      ? overridePath === 'newMedication'
        ? 'Medication'
        : 'Choose an Option'
      : 'Update Prescription';
    const description = !medication?.id
      ? overridePath === 'newMedication'
        ? 'Choose a medication from the list'
        : 'Select from your previous medications or choose to create a new one'
      : 'Update your prescription information.';

    const addMedicationToRequest = (medication: any) => {
      axiosClient.put(`/v1/medication_request/${medication.id}/${request?.id}`).then((response) => {
        if (response.status === 200) {
          window.location.reload();
        }
        setIsSaving(false);
      });
    };

    const canSave = !(quantity && prescription.dosage && prescription.variant);

    return (
      <Modal modal={modal} title={title} description={description} wide>
        {overridePath === 'newMedication' || path === 'newMedication' ? (
          <>
            {selectedDrug ? (
              <>
                <div className="flex flex-col gap-4 p-1 ">
                  <div className="flex flex-row items-center justify-between gap-4 p-2 border border-gray-300 rounded-lg">
                    <div>
                      <div className="flex items-center gap-2">
                        <div className="text-lg font-semibold">{selectedDrug}</div>
                      </div>
                      <div className="text-sm text-gray-500">
                        {drugRecords.find((r) => r.data.name === selectedDrug)?.data.generic}
                      </div>
                    </div>
                    <div className="cursor-pointer">
                      <Button variant="outline" onClick={() => setSelectedDrug('')}>
                        Change
                      </Button>
                    </div>
                  </div>

                  <div className="">
                    <Variant prescription={prescription} update={updatePrescription} drug={drug} />
                  </div>
                  {canViewDosage ? (
                    <>
                      <div className="">
                        <Dosage prescription={prescription} update={updatePrescription} drug={drug} />
                      </div>
                      {canViewPreference ? (
                        <div className="">
                          <Preference prescription={prescription} update={updatePrescription} drug={drug} />
                        </div>
                      ) : (
                        <>
                          <div className="flex flex-col gap-2 ">
                            <Label className="w-20 text-sm font-medium leading-tight text-neutral-800">
                              Preference
                            </Label>
                            <div className="flex flex-row gap-2 text-xs text-purple-700 ">
                              Select a dosage above to continue
                              <ArrowUp className="w-4 h-4" />
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="flex flex-col gap-2 ">
                        <Label className="w-20 text-sm font-medium leading-tight text-neutral-800">Dosage</Label>
                        <div className="flex flex-row gap-2 text-xs text-purple-700 ">
                          Select which type of {selectedDrug} above to continue
                          <ArrowUp className="w-4 h-4" />
                        </div>
                      </div>
                    </>
                  )}

                  {canViewTheRest && (
                    <>
                      <div className="">
                        <Label htmlFor="label">Label this medication</Label>
                        <Input
                          type="text"
                          id="label"
                          autoComplete="off"
                          placeholder="To help organize your medications add a label, e.g. Ben's Medication"
                          value={label}
                          onChange={(e) => setLabel(e.target.value)}
                        />
                      </div>
                      <div className="">
                        <Label htmlFor="quantity">
                          How many is it written for?
                          <span className="ml-1 text-red-500">*</span>
                        </Label>
                        <Input
                          type="number"
                          id="quantity"
                          autoComplete="off"
                          placeholder="How many?"
                          value={quantity}
                          onChange={(e) => setQuantity(e.target.value)}
                        />
                      </div>
                      <div className="flex gap-4 my-6 items-top">
                        <Switch
                          id="partial_stock_allowed"
                          onCheckedChange={() => setPartialStockAllowed(!partialStockAllowed)}
                          checked={partialStockAllowed ? true : false}
                        />

                        <div className="grid gap-1.5 leading-none">
                          <label
                            htmlFor="partial_stock_allowed"
                            className="-mt-1 text-sm font-medium leading-1 peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                          >
                            Are you willing to accept partial quantity if stock is limited?
                            <div className="mt-1 text-xs text-muted-foreground">
                              Needle will try to find the full quantity, but if it is not available, we will try to find
                              the closest quantity to what you need.
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="">
                        <Label htmlFor="notes" className="flex items-center gap-2">
                          <GoNote size={'20'} className="text-gray-500" />
                          Notes
                        </Label>
                        <Textarea
                          id="notes"
                          autoComplete="off"
                          placeholder="Add any additional information that will be useful for Needle to know."
                          className="h-28"
                          value={note}
                          onChange={(e) => setNote(e.target.value)}
                        />
                      </div>

                      <div className="flex items-center justify-between gap-8 ">
                        <Button className="w-full sm:w-auto" disabled={canSave || isSaving} onClick={saveMedication}>
                          {isSaving ? (
                            <>
                              <AiOutlineLoading className="w-4 h-4 mr-2 animate-spin" /> Saving Medication...
                            </>
                          ) : (
                            <>
                              <BsCapsulePill className="w-4 h-4 mr-2" />
                              {medication?.id ? 'Update Medication' : 'Add Medication'}
                            </>
                          )}
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : (
              <DrugSelector handleDrugSelect={handleDrugSelect} />
            )}
          </>
        ) : (
          <>
            <div className="flex flex-col gap-8 sm:flex-row">
              <div className="flex flex-col flex-1 gap-8">
                <div className="flex flex-col justify-start flex-1 gap-8 py-2 rounded-xl">
                  <div className="flex flex-col gap-0 text-center">
                    <div className="text-sm text-gray-500">
                      Create, Manage, and Store all your medications in one spot.
                    </div>
                  </div>

                  <Button className="rounded-full" onClick={() => setPath('newMedication')}>
                    <Plus className="w-4 h-4 mr-2" />
                    Create New Prescription
                  </Button>
                </div>
                <Button
                  className="border-gray-300 rounded-full shadow text-brand-dark-blue"
                  variant={'pink'}
                  onClick={() => photoModal.show()}
                >
                  <Camera className="w-4 h-4 mr-2" />
                  Snap a Photo!
                </Button>
              </div>
              <div className="border border-gray-200 rounded-xl">
                <ScrollArea className="h-72">
                  <div className="w-full overflow-scroll">
                    <Table className="w-full text-gray-400">
                      <TableBody>
                        {nonselectedMedications.map((medication) => (
                          <TableRow
                            className="cursor-pointer "
                            onClick={() => addMedicationToRequest(medication)}
                            key={medication.id}
                          >
                            <TableCell className="flex flex-row items-center gap-2 ">
                              <MedIcon medication={medication} />
                              <div className="flex flex-col gap-1 text-base text-gray-900 ">
                                <div className="font-semibold">{medication.medication}</div>
                                <div className="text-xs text-gray-500 truncate line-clamp-1">
                                  {medication.variant}{' '}
                                  {medication.label && (
                                    <>
                                      for{' '}
                                      <span className="text-gray-900 underline decoration-dotted decoration-purple-800">
                                        {' '}
                                        {medication.label}
                                      </span>
                                    </>
                                  )}
                                </div>
                              </div>
                            </TableCell>
                            <TableCell className="text-gray-500">
                              {medication.dosage} {medication.dosage_form}
                            </TableCell>
                            <TableCell className="text-gray-500">Qty: {medication.quantity}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </ScrollArea>
              </div>
            </div>
          </>
        )}
      </Modal>
    );
  },
);

export default MedicationModal;
