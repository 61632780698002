/* eslint-disable */
// @ts-nocheck
import { useMemo, useState, useRef } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { Popover, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/16/solid';
import { useVirtualizer } from '@tanstack/react-virtual';
import { DollarSign, Filter } from 'lucide-react';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa6';
import { useQueryClient } from 'react-query';
import FavoriteStar from '@/components/FavoriteStar';
import PharmacyIcon from '@/components/PharmacyIcon';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@/components/ui/hover-card';
import { Input } from '@/components/ui/input';
import { Switch } from '@/components/ui/switch';
import useMe from '@/hooks/useMe';
import usePharmacyFinder from '@/hooks/usePharmacyFinder';
import { IPharmacy, IRequest } from '@/types';
import axiosClient from '@/utils/axiosClient.ts';
import { cn } from '@/utils/utils';

interface DataTableProps {
  request: IRequest;
}

export function PharmaciesUnpaidTable({ request }: DataTableProps) {
  const pharmacyFinder = usePharmacyFinder(request);
  const queryClient = useQueryClient();
  const currentUser = useMe();

  const [pharmacySorted, setPharmacySorted] = useState<string | null>(null);
  const [distanceSorted, setDistanceSorted] = useState<string | null>('asc');
  const [toggleSorted, setToggleSorted] = useState<string | null>(null);
  const [searchFilter, setSearchFilter] = useState('');
  const [globalSwitchOn, setGlobalSwitchOn] = useState<boolean>(false);
  const [filterOption, setFilterOption] = useState<'all' | 'favorites'>('all');

  const pharmacies = pharmacyFinder?.data || [];

  const formatDistance = (distance: number | null) => {
    if (distance === null || isNaN(distance)) {
      return 'Unknown distance';
    }
    return `${distance.toFixed(2)} Miles`;
  };

  // Memoized list of filtered & sorted pharmacies
  const filteredPharmacies = useMemo(() => {
    const filterBySearch = (pharmacy: IPharmacy) => {
      let search = searchFilter.toLowerCase();
      let negate = false;

      if (search.startsWith('!')) {
        if (search.length < 2) return true;
        negate = true;
        search = search.substring(1);
      }

      const matches =
        pharmacy.id.toLowerCase().includes(search) ||
        pharmacy.name.toLowerCase().includes(search) ||
        pharmacy.address1.toLowerCase().includes(search) ||
        pharmacy.city.toLowerCase().includes(search) ||
        pharmacy.state.toLowerCase().includes(search) ||
        pharmacy.phone.toLowerCase().includes(search) ||
        pharmacy.zip.toLowerCase().includes(search);

      return negate ? !matches : matches;
    };

    const sortByField = (a: IPharmacy, b: IPharmacy, field: keyof IPharmacy) => {
      if (field === 'distance_miles') {
        const aDistance = parseFloat(a[field] as unknown as string);
        const bDistance = parseFloat(b[field] as unknown as string);
        return (aDistance - bDistance) * (distanceSorted === 'asc' ? 1 : -1);
      } else if (typeof a[field] === 'string' && typeof b[field] === 'string') {
        if (a[field] < b[field]) return pharmacySorted === 'asc' ? -1 : 1;
        if (a[field] > b[field]) return pharmacySorted === 'asc' ? 1 : -1;
      }
      return 0;
    };

    return pharmacies
      .filter((pharmacy) => !pharmacy.invalid_pharmacy)
      .filter(filterBySearch)
      .filter((pharmacy) => (filterOption === 'favorites' ? pharmacy.is_favorited : true))
      .sort((a, b) => {
        if (distanceSorted) return sortByField(a, b, 'distance_miles');
        if (pharmacySorted) return sortByField(a, b, 'name');
        if (toggleSorted) return (a.is_selected ? -1 : 1) * (toggleSorted === 'asc' ? 1 : -1);

        return 0;
      });
  }, [searchFilter, pharmacySorted, distanceSorted, toggleSorted, pharmacies, filterOption]);

  // Toggle select/unselect all
  const toggleSelect = async (type: string) => {
    queryClient.setQueryData([`v2/request_pharmacies/${request.id}/finder`], (oldRequestData: any) => {
      if (!oldRequestData) return oldRequestData;

      return oldRequestData.map((p: IPharmacy) => {
        if (p.is_paid) return p;

        return { ...p, is_selected: type === 'select' };
      });
    });

    axiosClient
      .post(`/v2/request_pharmacies/${request.id}/toggle`, {
        toggle: type,
        limit: type === 'select',
      })
      .then((response) => {
        queryClient.setQueryData([`v2/request_pharmacies/${request.id}/finder`], response.data);
      })
      .catch(() => {
        setGlobalSwitchOn(false);
      });
  };

  // Handle a single pharmacy selection
  // Snippet inside your handlePharmacySelected function:
  const handlePharmacySelected = async (pharmacy: IPharmacy) => {
    if (!pharmacy || !currentUser?.data || pharmacy.is_paid) return;

    try {
      pharmacy.is_selected = !pharmacy.is_selected;

      const response = await axiosClient.post(`/v2/request_pharmacies`, {
        request_id: request.id,
        pharmacy_id: pharmacy.id,
      });

      queryClient.setQueryData([`v2/request_pharmacies/${request.id}/finder`], (oldRequestData: any) => {
        if (!oldRequestData) return oldRequestData;

        return oldRequestData.map((p: IPharmacy) => {
          if (p.id === pharmacy.id) {
            return { ...p, is_selected: response.data.is_selected };
          }
          return p;
        });
      });
    } catch (error) {
      console.error('Error selecting pharmacy:', error);
    }
  };

  // Click handlers for sorting
  const handleSortToggle = () => {
    setPharmacySorted(pharmacySorted === 'asc' ? 'desc' : 'asc');
    setDistanceSorted(null);
    setToggleSorted(null);
  };

  const handleDistanceSortToggle = () => {
    setDistanceSorted(distanceSorted === 'asc' ? 'desc' : 'asc');
    setPharmacySorted(null);
    setToggleSorted(null);
  };

  const handleFilterClick = (e: React.MouseEvent) => {
    if (!currentUser?.data?.membership_is_active) {
      e.preventDefault();
      NiceModal.show('membership-modal', { triggerFeature: 'filter' });
    }
  };
  // ----------------------------
  // Virtualization Setup
  // ----------------------------
  const parentRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: filteredPharmacies.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 100, // approximate row height in px
  });

  const virtualItems = rowVirtualizer.getVirtualItems();

  return (
    <Card className="w-full">
      <CardHeader>
        <div className="flex justify-between">
          <CardTitle>Pharmacies</CardTitle>
          <div className="flex items-center justify-end gap-4">
            {/* Filter popover */}
            <Popover className="relative">
              {({ open, close }) => (
                <>
                  <Popover.Button
                    className="flex items-center gap-1 px-2 py-1 text-sm border border-gray-300 rounded-md"
                    onClick={handleFilterClick}
                  >
                    <Filter className="w-4 h-4" />
                    <span className="hidden md:inline">Filter</span>
                  </Popover.Button>
                  {currentUser?.data?.membership_is_active && (
                    <Transition
                      show={open}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel
                        static
                        className="absolute right-0 z-10 w-auto max-w-xs mt-2 bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        <div className="px-1 py-1">
                          <button
                            className={`${
                              filterOption === 'all' ? 'bg-gray-100' : ''
                            } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                            onClick={() => {
                              setFilterOption('all');
                              close();
                            }}
                          >
                            All Pharmacies
                          </button>
                          <button
                            className={`${
                              filterOption === 'favorites' ? 'bg-gray-100' : ''
                            } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                            onClick={() => {
                              setFilterOption('favorites');
                              close();
                            }}
                          >
                            My Pharmacies
                          </button>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  )}
                </>
              )}
            </Popover>

            <div className="flex items-center justify-between h-8 border border-gray-300 rounded-full">
              <div className="flex items-center justify-between h-6 px-1 py-0 md:px-2">
                <MagnifyingGlassIcon className={'w-5 h-5 text-brand-purple'} />
                <Input
                  type="text"
                  placeholder="Search"
                  value={searchFilter}
                  onChange={(e) => setSearchFilter(String(e.target.value))}
                  className="w-24 text-xs font-medium placeholder-gray-400 bg-transparent border-none md:w-28 focus-visible:ring-0 focus-visible:outline-none"
                />
              </div>
              {filteredPharmacies.length >= 0 && (
                <div className="flex-none pr-4 text-xs text-[#898b8e] font-normal flex">
                  {filteredPharmacies.length} Results
                </div>
              )}
            </div>
          </div>
        </div>
        <CardDescription>Select the pharmacies you want us to call by tapping or clicking on each row.</CardDescription>
      </CardHeader>

      <CardContent>
        <div className="w-full border border-gray-300 rounded-lg min-h-32">
          <div className="flex flex-col w-full">
            <div className="flex flex-row items-center px-1 py-1 text-xs bg-white border-b cursor-pointer md:px-2 md:py-2 border-b-gray-300">
              <div className="flex-1 w-[45%] min-w-0">
                <Button
                  variant="ghost"
                  size="sm"
                  className="items-center w-full h-8 gap-2 font-normal text-left truncate"
                  onClick={handleSortToggle}
                >
                  <span className="truncate">Pharmacy</span>
                  {pharmacySorted === 'desc' ? (
                    <FaArrowDown className="text-gray-600" />
                  ) : pharmacySorted === 'asc' ? (
                    <FaArrowUp className="text-gray-600" />
                  ) : (
                    <FaArrowDown className="text-gray-600" />
                  )}
                </Button>
              </div>
              <div className="flex-1 w-[45%] min-w-0">
                <Button
                  variant="ghost"
                  size="sm"
                  className="items-center w-full h-8 gap-2 font-normal text-left truncate"
                  onClick={handleDistanceSortToggle}
                >
                  <span className="truncate">Address</span>
                  {distanceSorted === 'desc' ? (
                    <FaArrowDown className="text-gray-600" />
                  ) : distanceSorted === 'asc' ? (
                    <FaArrowUp className="text-gray-600" />
                  ) : (
                    <FaArrowDown className="text-gray-600" />
                  )}
                </Button>
              </div>
              <div className="flex justify-end flex-shrink-0 w-12">
                <Switch
                  checked={globalSwitchOn}
                  onCheckedChange={(checked) => {
                    setGlobalSwitchOn(checked);
                    toggleSelect(checked ? 'select' : 'unselect');
                  }}
                />
              </div>
            </div>

            <div ref={parentRef} className="relative w-full overflow-y-auto max-h-96">
              <div
                style={{
                  height: rowVirtualizer.getTotalSize(),
                  position: 'relative',
                }}
                className="divide-y divide-gray-300"
              >
                {virtualItems.map(({ index, key, size, start }) => {
                  const pharmacy = filteredPharmacies[index];
                  if (!pharmacy) return null;

                  return (
                    <div
                      key={key}
                      onClick={() => handlePharmacySelected(pharmacy)}
                      className={cn(
                        'flex flex-row text-xs cursor-pointer hover:bg-gray-50 gap-1 pl-2',
                        pharmacy.is_selected && 'bg-[#f8f5ff]',
                        pharmacy.is_paid && 'bg-[#fff3f3] hover:bg-[#fff3f3] cursor-not-allowed',
                      )}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: size,
                        transform: `translateY(${start}px)`,
                      }}
                      title={
                        pharmacy.is_paid
                          ? 'You have already paid for this pharmacy. No further action is required.'
                          : ''
                      }
                    >
                      <div className="flex items-center w-12 p-0.5">
                        <PharmacyIcon pharmacy={pharmacy} />
                      </div>
                      <div className="w-full py-1.5">
                        <div className="flex flex-row flex-wrap gap-1">
                          <div className="text-sm font-semibold whitespace-pre-wrap">{pharmacy.name}</div>
                          <div>
                            <FavoriteStar request={request} pharmacy={pharmacy} pharmacies={pharmacies} />
                          </div>
                        </div>
                        <div className="mt-1">
                          <div className="flex flex-col text-xs">
                            <div className="leading-5 truncate whitespace-pre-wrap">{pharmacy.address1}</div>
                            <div className="truncate whitespace-pre-wrap">
                              {pharmacy.city}, {pharmacy.state}
                            </div>
                            <div className="mt-1 font-semibold">
                              {formatDistance(parseFloat(pharmacy.distance_miles as any))}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center justify-center w-16 max-w-16 min-w-16">
                        {pharmacy.is_paid ? (
                          <HoverCard>
                            <HoverCardTrigger>
                              <DollarSign />
                            </HoverCardTrigger>
                            <HoverCardContent>
                              You have already paid for this pharmacy. No further action is required.
                            </HoverCardContent>
                          </HoverCard>
                        ) : (
                          <Switch checked={pharmacy.is_selected} />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {filteredPharmacies.length === 0 && (
              <div className="flex flex-col max-w-xl gap-2 p-8 mx-auto text-center text-pretty">
                <div className="font-semibold">No pharmacies found with your criteria.</div>
                <div>
                  Try expanding your search radius by adjusting the circle on the map or moving the distance slider.
                </div>
              </div>
            )}
          </div>
        </div>
      </CardContent>
      <CardFooter>
        <div className="flex justify-end w-full">
          {filteredPharmacies.filter((p) => p.is_selected && !p.is_paid).length} Selected
        </div>
      </CardFooter>
    </Card>
  );
}
