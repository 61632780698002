import { useState, useEffect } from 'react';
import axios from 'axios';
import { REGEXP_ONLY_DIGITS_AND_CHARS } from 'input-otp';
import posthog from 'posthog-js';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { InputOTP, InputOTPGroup, InputOTPSlot } from '@/components/ui/input-otp';
import state from '../../utils/State';
import { Button } from '../ui/button';

const MFA = () => {
  const { token, value, method } = useParams<{ token?: string; value?: string; method?: string }>();
  const [code, setCode] = useState('');

  const setSessionId = useSetRecoilState(state.sessionId);
  const setCurrentUser = useSetRecoilState(state.currentUser);

  const [error, setError] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  // Handle paste events
  useEffect(() => {
    const handlePaste = (e: ClipboardEvent) => {
      const pastedText = e.clipboardData?.getData('Text').toUpperCase();
      if (pastedText && pastedText.length === 6 && new RegExp(REGEXP_ONLY_DIGITS_AND_CHARS).test(pastedText)) {
        e.preventDefault();
        setCode(pastedText);
      }
    };

    document.addEventListener('paste', handlePaste);

    return () => {
      document.removeEventListener('paste', handlePaste);
    };
  }, []);

  useEffect(() => {
    if (code.length === 6 && !isSaving) {
      validateCode();
    }
  }, [code]);

  if (!token || !value || !method) {
    window.location.href = '/login';
    return null;
  }

  const validateCode = () => {
    if (isSaving) return;
    setIsSaving(true);
    setError('');

    if (code.length === 6) {
      axios
        .post(`${import.meta.env.VITE_NEEDLE_API_URL}/v1/sessions/mfa`, {
          user_id: token,
          code: code,
        })
        .then((response) => {
          setIsSaving(false);
          if (response && response.data && response.data.token && response.data.user) {
            setSessionId(response.data.token);
            setCurrentUser(response.data.user);

            posthog.capture('sign_in', { account_created: response.data.user.created_at });

            posthog.identify(response.data.user.id, {
              email: response.data.user.email,
              name: response.data.user.name,
              phone: response.data.user.phone,
              created: response.data.user.created_at,
              plan: response.data.user.plan,
            });

            window.location.href = `/`;
          } else {
            setError('Invalid Authorization Code');
            setCode('');
          }
        })
        .catch((error) => {
          setSessionId(null);
          setCurrentUser({});
          setIsSaving(false);
          setError(error.response?.data?.errors[0] || 'Invalid Authorization Code');
          setCode('');
        });
    } else {
      setIsSaving(false);
      setError('Invalid Authorization Code');
    }
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          validateCode();
        }}
        className="flex flex-col justify-center max-w-lg gap-4 mx-auto sm:max-w-md"
      >
        <label htmlFor="totp" className="text-center text-slate-500 text-md">
          Enter the code that was sent to the email address
        </label>
        <div className="font-bold text-center text-slate-700">{value}</div>
        <div className="flex justify-center mt-8">
          <InputOTP
            maxLength={6}
            value={code}
            onChange={(value) => setCode(value.toUpperCase())}
            pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
            autoFocus
            inputMode="text"
            autoComplete="one-time-code"
            id="totp"
            name="totp"
          >
            <InputOTPGroup className="bg-white" inputMode="text">
              <InputOTPSlot index={0} />
              <InputOTPSlot index={1} />
              <InputOTPSlot index={2} />
              <InputOTPSlot index={3} />
              <InputOTPSlot index={4} />
              <InputOTPSlot index={5} />
            </InputOTPGroup>
          </InputOTP>
        </div>
        {error && <div className="text-sm font-bold text-center text-red-500">{error}</div>}

        <Button type="submit" color="purple" className="mt-4 rounded-full " disabled={isSaving || code.length !== 6}>
          Validate Code
        </Button>

        <Button variant={'link'} asChild>
          <Link
            className="w-full text-sm text-center text-gray-800 cursor-pointer hover:text-primary hover:underline hover:duration-500"
            to={'/login'}
          >
            Need a new code or change email address?
          </Link>
        </Button>
      </form>
    </>
  );
};

export default MFA;
