import { useEffect } from 'react';
import Intercom from '@intercom/messenger-js-sdk';
import useMe from '@/hooks/useMe';

type Props = {
  children: React.ReactNode;
};

const IntercomWrapper = ({ children }: Props) => {
  const currentUser = useMe();

  useEffect(() => {
    if (currentUser) {
      Intercom({
        app_id: 'qmr8k6wf',
        needle_application: 'providers',
        admin: currentUser?.data?.admin,
        user_id: currentUser?.data?.id,
        name: currentUser?.data?.name,
        medical_provider: currentUser?.data?.medical_provider,
        membership_is_active: currentUser?.data?.membership_is_active,
        membership_tier: currentUser?.data?.membership_tier,
        total_credits: currentUser?.data?.total_credits,
        phone: currentUser?.data?.phone,
        email: currentUser?.data?.email,
        created_at: new Date(currentUser?.data?.created_at).getTime(),
      } as any);
    }
  }, [currentUser]);

  return children;
};

export default IntercomWrapper;
